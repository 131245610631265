import { LanguageState } from '@modules/language/languageSlice';
import zhTranslations from '@translations/zh.json';
import enTranslations from '@translations/en.json';
import amqLogo from '@assets/amq_logo.png';
import { LanguageEnum } from '@customTypes/enums/language.enum';
import { GenderEnum } from '@customTypes/enums/gender.enum';
import { BrandEnum } from '@customTypes/enums/brand.enum';
import { SalesforceTypeEnum } from '@customTypes/enums/salesforce-type.enum';
import heroMen from '@assets/hero_image_landing_page_men.jpg';
import heroWomen from '@assets/hero_image_landing_page_women.jpg';
import { StatusEnum } from '@customTypes/enums/status.enum';
import { Dispatch, SetStateAction } from 'react';
import { LogMessageEnum, LogTypeEnum } from '@customTypes/enums/log.enum';

/**
 * Return translations from language
 * @param language
 */
export const getLanguageAndTranslations = (language: string): LanguageState => {
  if (language === LanguageEnum.CHINESE) {
    return { language: LanguageEnum.CHINESE, translations: zhTranslations };
  }
  return { language: LanguageEnum.ENGLISH, translations: enTranslations };
};

/**
 * Return brand logo from brand
 * @param brandCode
 */
export const getLogo = (brandCode: BrandEnum) => {
  if (isAmqBrandCode(brandCode)) {
    return amqLogo;
  }
  return '';
};

/**
 * Convert brand to brand enum
 * @param brandId
 */
export const convertBrandIdToBrandEnum = (brandId: string) => {
  if (brandId === '55') return BrandEnum.AMQ;
  return BrandEnum.DEFAULT;
};

/**
 * Convert gender to gender enum
 * @param gender
 */
export const convertGenderToGenderEnum = (gender: string) => {
  if (['M', 'H'].includes(gender)) return GenderEnum.MEN;
  return GenderEnum.WOMEN;
};

/**
 * Differentiate between AMQ and other customers
 * @param brandCode
 */
export const isAmqBrandCode = (brandCode: BrandEnum) => brandCode === BrandEnum.AMQ;

/**
 * Differentiate between women and men customers
 * @param gender
 */
export const isWomenGender = (gender: GenderEnum) => gender === GenderEnum.WOMEN;

/**
 * Load images before displaying them
 * @param url
 */
export const loadImage = (url: string): Promise<string> => new Promise((resolve, reject) => {
  const loadImg = new Image();
  loadImg.src = url;
  loadImg.onload = () => resolve(url);
  loadImg.onerror = (err) => reject(err);
});

/**
 * Differentiate between retail and online customers
 * @param sfType
 */
export const isOnlineClient = (sfType: string) => sfType === SalesforceTypeEnum.JOURNEYONLINE;

/**
 * Get welcome item to display
 * @param isWomen
 * @param language
 */
export const getItem = (isWomen: boolean, language: LanguageEnum): string => {
  if (!isWomen) return heroMen;
  return heroWomen;
};

/**
 * Conditions to display error page
 * @param brand
 * @param retrievedId
 * @param questionnaireId
 * @param retrievedBrandCode
 * @param status
 * @param genderStatus
 * @param setShouldDisplayError
 * @param setErrorType
 * @param setErrorMessage
 */
export const displayError = (brand: BrandEnum, retrievedId: string, questionnaireId: string, retrievedBrandCode: string, status: StatusEnum, genderStatus: StatusEnum, setShouldDisplayError: Dispatch<SetStateAction<boolean>>, setErrorType:Dispatch<SetStateAction<string>>, setErrorMessage: Dispatch<SetStateAction<string>>) => {
  const brandError = !isAmqBrandCode(brand) && status === StatusEnum.FULLFIELD;
  if (status === StatusEnum.REJECTED) {
    setErrorMessage(replacePlaceholder(LogMessageEnum.RECOMMENDATION, [retrievedId]));
  }
  if (genderStatus === StatusEnum.REJECTED) {
    setErrorMessage(LogMessageEnum.SURVEY);
  }
  if (brandError) {
    setErrorMessage(replacePlaceholder(LogMessageEnum.BRAND, [BrandEnum.AMQ, retrievedBrandCode]));
  }
  if (status === StatusEnum.FULLFIELD && !questionnaireId) {
    setErrorMessage(LogMessageEnum.QUESTIONNAIRE_ID);
  }
  if (status === StatusEnum.REJECTED || genderStatus === StatusEnum.REJECTED || brandError || (status === StatusEnum.FULLFIELD && !questionnaireId)) {
    setShouldDisplayError(true);
    setErrorType(LogTypeEnum.ERROR);
  }
};

/**
 * Replace placeholder in enums
 * @param myString
 * @param replacementValues
 */
export const replacePlaceholder = (myString: string, replacementValues: string[]): string => {
  for (const replacementValue of replacementValues) {
    myString = myString.replace(/§.+?§/, replacementValue);
  }
  return myString;
};
