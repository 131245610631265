import React from 'react';
import { DivLoader1, DivLoader2, DivLoader3, FullPageLoaderContainer, RingLoader } from '@commons/Loader/style';

const Loader = () => (
  <RingLoader>
    <DivLoader1 />
    <DivLoader2 />
    <DivLoader3 />
  </RingLoader>
);

export const FullPageLoader = () => (
  <FullPageLoaderContainer>
    <Loader />
  </FullPageLoaderContainer>
);

export default Loader;
