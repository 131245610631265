import styled from 'styled-components/macro';
import screenSizes from '@customTypes/constants/screen-size.constant';

export const LogoContainer = styled.div`
  margin: 75px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    margin: 40px 0 30px;  
  }
`;

export const StyledLogo = styled.img`
  width: 600px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    width: 270px;
  }
`;
