import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@services/api';
import { StatusEnum } from '@customTypes/enums/status.enum';
import { SliceEnum } from '@customTypes/enums/slice.enum';
import { GenderEnum } from '@customTypes/enums/gender.enum';
import { convertGenderToGenderEnum } from '@services/utils';

export type GenderState = {
  gender: GenderEnum;
  genderStatus: StatusEnum;
};

export const genderInitialState: GenderState = {
  gender: GenderEnum.WOMEN,
  genderStatus: StatusEnum.DEFAULT
};

export const fetchSurvey = createAsyncThunk('survey/surveyId', async (surveyId?: string) => {
  const response = await api.getSurvey(surveyId);
  return response.data;
});

const genderSlice = createSlice({
  name: SliceEnum.GENDER,
  initialState: genderInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSurvey.pending, (state) => {
      state.genderStatus = StatusEnum.PENDING;
    });
    builder.addCase(fetchSurvey.fulfilled, (state, { payload }) => {
      state.gender = convertGenderToGenderEnum(payload.gender);
      state.genderStatus = StatusEnum.FULLFIELD;
    });
    builder.addCase(fetchSurvey.rejected, (state) => {
      state.genderStatus = StatusEnum.REJECTED;
    });
  }
});

export default genderSlice.reducer;
