export enum LogTypeEnum {
  SUCCESS = 'AMQ Web App Success',
  ERROR = 'AMQ Web App Error'
}

export enum LogMessageEnum {
  SUCCESS = 'Web App displayed to the customer',
  DEFAULT_ERROR = 'Default Error Message',
  RECOMMENDATION = 'Something went wrong when calling /recommendation with §expectedId§ id',
  SURVEY = 'Something went wrong when calling /survey',
  BRAND = 'Wrong brand retrieved, expected §expectedBrand§ and retrieved code §retrievedBrand§',
  QUESTIONNAIRE_ID = 'No questionnaire id retrieved in recommendation'
}
