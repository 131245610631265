import React, { useEffect } from 'react';
import PageContainer from '@commons/PageContainer';
import { ErrorDescriptionText, ErrorText } from '@pages/ErrorPage/style';
import useTranslation from '@services/hooks/useTranslation';
import Header from '@pages/LandingPage/Header';
import { updateLanguage } from '@modules/language/languageSlice';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { getLanguage } from '@modules/language/languageSelector';
import { HeaderBorder } from '@pages/LandingPage/style';
import api from '@services/api';
import { getError } from '@modules/error/errorSelector';

const ErrorPage = () => {
  const t = useTranslation();
  // If the endpoint to retrieve the recommendation crashes, the translations must be generated with updateLanguage
  const dispatch = useAppDispatch();
  const language = useAppSelector(getLanguage);
  dispatch(updateLanguage(language));

  const error = useAppSelector(getError);
  const title = t('title');

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    (async function fetch() {
      await api.postLog(error.id, error.type, error.message);
    }());
  }, []);

  return (
    <PageContainer>
      <Header />
      <HeaderBorder />
      <ErrorText>{t('error.title.text')}</ErrorText>
      <ErrorDescriptionText>{t('error.description.text')}</ErrorDescriptionText>
    </PageContainer>
  );
};
export default ErrorPage;
