import styled from 'styled-components/macro';
import screenSizes from '@customTypes/constants/screen-size.constant';

export const ErrorText = styled.div`
  margin-top: 10%;
  margin-bottom: 20px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    margin-top: 50%;
  }
`;

export const ErrorDescriptionText = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 300;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    width: 80%;
  }
`;
