import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LandingPage from '@pages/LandingPage';
import ErrorPage from '@pages/ErrorPage';

const App = () => (
  <BrowserRouter basename="/recommendation">
    <Routes>
      <Route path="/:recommendationId" element={<LandingPage />} />
      <Route path="/error" element={<ErrorPage />} />
    </Routes>
  </BrowserRouter>
);

export default App;
