import React from 'react';
import { LogoContainer, StyledLogo } from '@pages/LandingPage/Header/style';
import { useAppSelector } from '@modules/store';
import { getBrand } from '@modules/brand/brandSelector';

const Header = () => {
  const brandObject = useAppSelector(getBrand);
  return <LogoContainer><StyledLogo src={brandObject.logo} alt="logo" /></LogoContainer>;
};

export default Header;
