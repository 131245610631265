import axios, { AxiosResponse } from 'axios';
import getEnv from '@services/getEnv';
import { RecommendationInterface } from '@customTypes/interfaces/recommendation.interface';
import { SurveyInterface } from '@customTypes/interfaces/survey.interface';
import LogConstants from '@customTypes/constants/log.constants';

const instance = axios.create({
  baseURL: getEnv('REACT_APP_API_BASE_URL'),
  withCredentials: true
});

const api = {
  getRecommendation: (recommendationId: string = ''): Promise<AxiosResponse<RecommendationInterface>> => instance.get(`recommendation/${recommendationId}`),
  getSurvey: (surveyId: string = ''): Promise<AxiosResponse<SurveyInterface>> => instance.get(`survey/${surveyId}?useUniqueId=true`),
  postLog: (id: string | undefined, type: string, message: string): Promise<AxiosResponse<string>> => instance.post(
    'logger',
    {
      id: id || LogConstants.id,
      application: LogConstants.application,
      type,
      message
    }
  )
};

export default api;
