import React from 'react';
import {
  ArticleDescriptionText,
  ArticleTitleText,
  ContactButton,
  DiscoverText,
  ItemImage,
  Look,
  LookWrapper,
  ProductImage,
  ProductParentImage,
  ProductParentWrapper
} from '@pages/LandingPage/Body/style';

import useTranslation from '@services/hooks/useTranslation';
import { useAppSelector } from '@modules/store';
import { getRecommendation } from '@modules/recommendation/recommendationSelector';
import { getItem, isOnlineClient, isWomenGender } from '@services/utils';
import { ItemContainerProps, LookProps, ProductProps } from '@customTypes/types/body-props.type';
import { contactClick, itemAndLookClick, productClick } from '@pages/LandingPage/Body/service';
import { getGender } from '@modules/gender/genderSelector';
import { getLanguage } from '@modules/language/languageSelector';

const Body = () => {
  const t = useTranslation();
  const recommendationObject = useAppSelector(getRecommendation);
  const genderObject = useAppSelector(getGender);

  const { gender } = genderObject;
  const { recommendation } = recommendationObject;
  const { looks, products, sfType, advisor, heroImage, landingImage } = recommendation;
  return (
    <>
      <ItemContainer gender={gender} heroImage={heroImage} sfType={sfType} />
      <LookContainer looks={looks} heroImage={heroImage} sfType={sfType} />
      <DiscoverText>{t('products.discover')}</DiscoverText>
      <ProductContainer products={products} sfType={sfType} />
      <ContactButton type="button" onClick={() => contactClick(sfType, gender, advisor?.email, landingImage, heroImage)}>{isOnlineClient(sfType) ? t('products.collection') : t('products.contact')}</ContactButton>
    </>
  );
};

const ItemContainer: React.FC<ItemContainerProps> = ({ gender, heroImage, sfType }) => {
  const t = useTranslation();
  const isWomen = isWomenGender(gender);
  const language = useAppSelector(getLanguage);
  const item = getItem(isWomen, language);
  return (
    <>
      <ItemImage src={item} alt={isWomen ? 'Bag' : 'Suit'} onClick={() => itemAndLookClick(sfType, heroImage)} sfType={sfType} />
      <ArticleTitleText>{t(`item.${gender}.name`)}</ArticleTitleText>
      <ArticleDescriptionText>{t(`item.${gender}.description`)}</ArticleDescriptionText>
    </>
  );
};

const LookContainer: React.FC<LookProps> = ({ looks, heroImage, sfType }) => (
  <LookWrapper>
    {looks.map((look, index) => <Look lookIndex={index} lookUrl={look.ecom_look_urls[0]?.medium || look.ecom_look_urls[0]?.thumbnail} onClick={() => itemAndLookClick(sfType, heroImage)} sfType={sfType} />)}
  </LookWrapper>
);

const ProductContainer: React.FC<ProductProps> = ({ products, sfType }) => (
  <ProductParentWrapper>
    <ProductParentImage>
      <ProductImage src={products[0]?.externalMediaUrls[0]} alt="product1" onClick={() => productClick(sfType, products[0]?.url)} sfType={sfType} />
      <ProductImage src={products[1]?.externalMediaUrls[0]} alt="product2" onClick={() => productClick(sfType, products[1]?.url)} sfType={sfType} />
      <ProductImage src={products[2]?.externalMediaUrls[0]} alt="product3" onClick={() => productClick(sfType, products[2]?.url)} sfType={sfType} />
      <ProductImage src={products[3]?.externalMediaUrls[0]} alt="product4" onClick={() => productClick(sfType, products[3]?.url)} sfType={sfType} />
      <ProductImage src={products[4]?.externalMediaUrls[0]} alt="product5" onClick={() => productClick(sfType, products[4]?.url)} sfType={sfType} />
      <ProductImage src={products[5]?.externalMediaUrls[0]} alt="product6" onClick={() => productClick(sfType, products[5]?.url)} sfType={sfType} />
      <ProductImage src={products[6]?.externalMediaUrls[0]} alt="product7" onClick={() => productClick(sfType, products[6]?.url)} sfType={sfType} />
      <ProductImage src={products[7]?.externalMediaUrls[0]} alt="product8" onClick={() => productClick(sfType, products[7]?.url)} sfType={sfType} />
    </ProductParentImage>
  </ProductParentWrapper>
);

export default Body;
