import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '@services/api';
import { StatusEnum } from '@customTypes/enums/status.enum';
import { RecommendationInterface } from '@customTypes/interfaces/recommendation.interface';
import { SliceEnum } from '@customTypes/enums/slice.enum';

export type RecommendationState = {
  recommendation: RecommendationInterface;
  status: StatusEnum;
};

export const recommendationInitialState: RecommendationState = {
  recommendation: {
    id: '',
    customerId: '',
    questionnaireId: '',
    products: [],
    looks: [],
    recommendedAt: '',
    createdAt: '',
    updatedAt: '',
    lang: '',
    brandCode: '',
    sfType: '',
    heroImage: ''
  },
  status: StatusEnum.DEFAULT
};

export const fetchRecommendation = createAsyncThunk('recommendation/recommendationId', async (recommendationId?: string) => {
  const response = await api.getRecommendation(recommendationId);
  return response.data;
});

const recommendationSlice = createSlice({
  name: SliceEnum.RECOMMENDATION,
  initialState: recommendationInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRecommendation.pending, (state) => {
      state.status = StatusEnum.PENDING;
    });
    builder.addCase(fetchRecommendation.fulfilled, (state, { payload }) => {
      state.recommendation = payload;
      state.status = StatusEnum.FULLFIELD;
    });
    builder.addCase(fetchRecommendation.rejected, (state) => {
      state.status = StatusEnum.REJECTED;
    });
  }
});

export default recommendationSlice.reducer;
