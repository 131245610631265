import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { getLanguageAndTranslations } from '@services/utils';
import { Translation } from '@customTypes/interfaces/translation.interface';
import { LanguageEnum } from '@customTypes/enums/language.enum';
import { SliceEnum } from '@customTypes/enums/slice.enum';

export type LanguageState = {
  language: LanguageEnum;
  translations: Translation | null;
};

export const languageInitialState: LanguageState = {
  language: LanguageEnum.ENGLISH,
  translations: null
};

const languageSlice = createSlice({
  name: SliceEnum.LANGUAGE,
  initialState: languageInitialState,
  reducers: {
    updateLanguage: (state, action: PayloadAction<string>) => {
      const languageAndTranslations = getLanguageAndTranslations(action.payload);
      state.language = languageAndTranslations.language;
      state.translations = languageAndTranslations.translations;
    }
  },
});

export const { updateLanguage } = languageSlice.actions;

export default languageSlice.reducer;
