import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SliceEnum } from '@customTypes/enums/slice.enum';

export type ErrorState = {
  id: string | undefined,
  type: string,
  message: string
};

export const errorInitialState: ErrorState = {
  id: '',
  type: '',
  message: ''
};

const errorSlice = createSlice({
  name: SliceEnum.ERROR,
  initialState: errorInitialState,
  reducers: {
    updateError: (state, action: PayloadAction<ErrorState>) => action.payload
  },
});

export const { updateError } = errorSlice.actions;

export default errorSlice.reducer;
