const links = {
  account: 'https://www.alexandermcqueen.com/login',
  contact: 'https://www.alexandermcqueen.com/contactus',
  stores: 'https://www.alexandermcqueen.com/storelocator',
  collection: 'https://www.alexandermcqueen.com/en-fr/women/collections/aw22-collection',
  service: 'https://www.alexandermcqueen.com/faq',
  privacy: 'https://www.alexandermcqueen.com/legal-pages/privacy-policy.html'
};

export default links;
