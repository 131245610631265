import styled, { css } from 'styled-components/macro';
import { LookUrlProps, ProductProps } from '@customTypes/types/body-props.type';
import { isOnlineClient } from '@services/utils';
import screenSizes from '@customTypes/constants/screen-size.constant';

export const ContactButton = styled.button`
  margin-top: 48px;
  cursor: pointer;
  background-color: black;
  color: white;
  width: 300px;
  height: 40px;
  font-weight: 500;
  font-size: 18px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    width: 180px;
    height: 40px;
    font-size: 12px;
    margin-top: 30px;
  }
`;

export const DiscoverText = styled.div`
  margin-top: 30px;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    margin-top: 24px;
    font-size: 14px;
  }
`;

export const ItemImage = styled.img.attrs((props: ProductProps) => ({
  sfType: props.sfType
}))`
  cursor: ${(props) => isOnlineClient(props.sfType) && 'pointer'};
  width: 650px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    width: 300px;
  }
`;

export const ProductParentWrapper = styled.div`
  margin-top: 17px;
  width: 1304px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    width: 370px;
    margin-top: 36px;
  }
`;

export const ProductParentImage = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ProductImage = styled.img.attrs((props: ProductProps) => ({
  sfType: props.sfType
}))`
  display: inline-block;
  cursor: ${(props) => isOnlineClient(props.sfType) && 'pointer'};
  margin: 13px;
  flex-grow: 1;
  width: 300px;
  height: 437px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    width: 158px;
    margin: 9px;
    height: 211px;
  }
`;

export const ArticleTitleText = styled.div`
  margin-top: 56px;
  font-weight: bold;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    width: 300px;
    text-align: center;
    margin-top: 24px;
    font-size: 10px;
  }
`;

export const ArticleDescriptionText = styled.div`
  margin-top: 12px;
  width: 640px;
  text-align: center;
  font-weight: 300;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    width: 300px;
    font-size: 12px;
    margin-top: 8px;
  }
`;

export const LookWrapper = styled.div`
  margin-top: 75px;
  display: grid;
  grid-template-columns: 308px 308px 308px 308px;
  gap: 15px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    margin-top: 24px;
    gap: 11px;
    grid-template-columns: 136px 198px;
    grid-template-rows: 288px 42px 207px;
  }
`;

export const Look = styled.div<LookUrlProps>`
  cursor: ${(props) => isOnlineClient(props.sfType) && 'pointer'};
  background-image: ${(props) => `url(${props.lookUrl})`};
  background-size: cover;
  background-position: center;
  ${(props) => {
    switch (props.lookIndex) {
      case 0:
        return css`
          width: 308px;
          height: 775px;
          @media screen and (max-width: ${screenSizes.desktop.width}) {
            width: 136px;
            height: 329px;
            grid-row-start: 1;
            grid-row-end: 2;
          }
        `;
      case 1:
        return css`
          width: 308px;
          height: 775px;
          @media screen and (max-width: ${screenSizes.desktop.width}) {
            width: 198px;
            height: 288px;
          }
        `;
      case 2:
        return css`
          width: 308px;
          height: 775px;
          @media screen and (max-width: ${screenSizes.desktop.width}) {
            width: 198px;
            height: 249px;
            grid-column-start: 2;
            grid-row-start: 2;
          }
        `;
      case 3:
        return css`
          width: 308px;
          height: 775px;
          @media screen and (max-width: ${screenSizes.desktop.width}) {
            grid-column-start: 1;
            grid-column-end: 3;
            margin-top: -10px;
            width: 136px;
            height: 207px;
          }
        `;
      default:
        return css`
          display: none;
        `;
    }
  }};
`;
