import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/index.css';
import { Provider } from 'react-redux';
import { store } from '@modules/store';
import App from '@/App';

declare global {
  interface Window {
    _env_: {
      [env: string]: string | undefined;
    };
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
