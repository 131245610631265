import React, { useEffect, useState } from 'react';
import Header from '@pages//LandingPage/Header';
import Body from '@pages//LandingPage/Body';
import Footer from '@pages/LandingPage/Footer';
import PageContainer from '@commons/PageContainer';
import { FooterBorder } from '@pages/LandingPage/style';
import { useAppDispatch, useAppSelector } from '@modules/store';
import { getRecommendation } from '@modules/recommendation/recommendationSelector';
import { Navigate, useParams } from 'react-router-dom';
import { fetchRecommendation } from '@modules/recommendation/recommendationSlice';
import { updateLanguage } from '@modules/language/languageSlice';
import { updateBrand } from '@modules/brand/brandSlice';
import { FullPageLoader } from '@commons/Loader';
import {
  convertBrandIdToBrandEnum,
  displayError,
  getItem,
  isAmqBrandCode,
  isWomenGender,
  loadImage
} from '@services/utils';
import { StatusEnum } from '@customTypes/enums/status.enum';
import { RecommendationId } from '@customTypes/types/landing-page.type';
import { fetchSurvey } from '@modules/gender/genderSlice';
import { getGender } from '@modules/gender/genderSelector';
import { getLanguage } from '@modules/language/languageSelector';
import { updateError } from '@modules/error/errorSlice';
import { LogMessageEnum, LogTypeEnum } from '@customTypes/enums/log.enum';
import api from '@services/api';
import useTranslation from '@services/hooks/useTranslation';

const LandingPage = () => {
  const t = useTranslation();
  const { recommendationId } = useParams<RecommendationId>();
  const dispatch = useAppDispatch();
  const recommendationObject = useAppSelector(getRecommendation);
  const genderObject = useAppSelector(getGender);
  const language = useAppSelector(getLanguage);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shouldDisplayError, setShouldDisplayError] = useState<boolean>(false);
  const [errorId, setErrorId] = useState<string | undefined>('');
  const [errorType, setErrorType] = useState<string>(LogTypeEnum.ERROR);
  const [errorMessage, setErrorMessage] = useState<string>(LogMessageEnum.DEFAULT_ERROR);

  const { recommendation, status } = recommendationObject;
  const { gender, genderStatus } = genderObject;
  const title = t('title');

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    setErrorId(recommendationId);
    dispatch(fetchRecommendation(recommendationId));
    if (recommendation.lang) dispatch(updateLanguage(recommendation.lang));
    if (recommendation.brandCode) dispatch(updateBrand(convertBrandIdToBrandEnum(recommendation.brandCode)));
    if (recommendation.questionnaireId) {
      dispatch(fetchSurvey(recommendation.questionnaireId));
    }
  }, [dispatch, recommendation.brandCode, recommendation.lang, recommendationId, recommendation.questionnaireId]);

  const isWomen = isWomenGender(gender);
  const brand = convertBrandIdToBrandEnum(recommendation.brandCode);
  const item = getItem(isWomen, language);

  useEffect(() => {
    displayError(brand, recommendationId || '', recommendation.questionnaireId, recommendation.brandCode, status, genderStatus, setShouldDisplayError, setErrorType, setErrorMessage);
  }, [brand, genderStatus, recommendation.brandCode, recommendation.questionnaireId, recommendationId, status]);

  useEffect(() => {
    (async function load() {
      await loadImage(item);
      setIsLoading(false);
    }());
  }, [isWomen, item]);

  const isPageLoaded = (status === StatusEnum.FULLFIELD && genderStatus === StatusEnum.FULLFIELD && !isLoading);
  const displayLandingPage = isPageLoaded && isAmqBrandCode(brand);

  useEffect(() => {
    (async function fetch() {
      if (displayLandingPage) await api.postLog(recommendationId, LogTypeEnum.SUCCESS, LogMessageEnum.SUCCESS);
    }());
  }, [displayLandingPage, recommendationId]);

  if (displayLandingPage) {
    return (
      <PageContainer>
        <Header />
        <Body />
        <FooterBorder />
        <Footer />
      </PageContainer>
    );
  }

  if (shouldDisplayError) {
    dispatch(updateError({ id: errorId, type: errorType, message: errorMessage }));
    return <Navigate to="/error" replace />;
  }
  return <FullPageLoader />;
};

export default LandingPage;
