import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import brandReducer from '@modules/brand/brandSlice';
import languageReducer from '@modules/language/languageSlice';
import recommendationReducer from '@modules/recommendation/recommendationSlice';
import genderReducer from '@modules/gender/genderSlice';
import errorReducer from '@modules/error/errorSlice';

const rootReducer = combineReducers({
  recommendationReducer,
  languageReducer,
  brandReducer,
  genderReducer,
  errorReducer
});

export const store = configureStore({
  reducer: rootReducer
});

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
