import styled from 'styled-components/macro';
import screenSizes from '@customTypes/constants/screen-size.constant';

export const HeaderBorder = styled.div`
  border-bottom: 1px solid #BDBDBD;
  width: 100%;
  height: 1px;
  display: none;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    display: inherit;
    margin-bottom: 32px;
  }
`;

export const FooterBorder = styled.div`
  border-bottom: 2.41px solid #E6E6E6;
  width: 80%;
  height: 1px;
  margin-top: 60px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    margin-top: 50px;
    border-bottom: 1px solid #E6E6E6;
    width: 90%;
  }
`;
