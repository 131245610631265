import { isOnlineClient, isWomenGender } from '@services/utils';
import links from '@customTypes/constants/link.constants';
import { GenderEnum } from '@customTypes/enums/gender.enum';

export const productClick = (sfType: string, productUrl: string) => {
  (isOnlineClient(sfType) && productUrl) && window.open(productUrl);
};

export const contactClick = (sfType: string, gender: GenderEnum, advisorEmail?: string, landingImage?: string, itemAndLookUrl?: string) => {
  if (isOnlineClient(sfType)) {
    isWomenGender(gender) ? window.open(landingImage) : window.open(itemAndLookUrl);
  } else {
    window.open(`mailto:${advisorEmail}`);
  }
};

export const itemAndLookClick = (sfType: string, itemAndLookUrl?: string) => {
  (isOnlineClient(sfType) && itemAndLookUrl) && window.open(itemAndLookUrl);
};
