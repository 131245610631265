import { getTranslations } from '@modules/language/languageSelector';
import { useAppSelector } from '@modules/store';

export default () => function useTranslation(key: string) {
  const translations = useAppSelector(getTranslations);
  if (!translations) return key;
  const translation = translations[key];
  if (translation === undefined || translation === null) return key;
  return translation;
};
