import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import amqLogo from '@assets/amq_logo.png';
import { getLogo } from '@services/utils';
import { BrandEnum } from '@customTypes/enums/brand.enum';
import { SliceEnum } from '@customTypes/enums/slice.enum';

export type BrandState = {
  brand: BrandEnum;
  logo: string;
};

export const brandInitialState: BrandState = {
  brand: BrandEnum.AMQ,
  logo: amqLogo
};

const brandSlice = createSlice({
  name: SliceEnum.BRAND,
  initialState: brandInitialState,
  reducers: {
    updateBrand: (state, action: PayloadAction<BrandEnum>) => {
      state.brand = action.payload;
      state.logo = getLogo(action.payload);
    }
  },
});

export const { updateBrand } = brandSlice.actions;

export default brandSlice.reducer;
