import React from 'react';
import StylePageContainer from '@commons/PageContainer/style';

type Props = {
  children: React.ReactNode
};

const PageContainer: React.FC<Props> = ({ children }) => <StylePageContainer>{children}</StylePageContainer>;

export default PageContainer;
