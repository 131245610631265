import React from 'react';
import useTranslation from '@services/hooks/useTranslation';
import { AddressText, ContactWrapper, FooterLink, Link, LinksWrapper } from '@pages/LandingPage/Footer/style';
import links from '@customTypes/constants/link.constants';

const LinksContainer = () => {
  const t = useTranslation();
  return (
    <LinksWrapper>
      <Link href={links.stores} rel="noopener noreferrer" target="_blank">{t('footer.stores')}</Link>
      -
      <Link href={links.service} rel="noopener noreferrer" target="_blank">{t('footer.service')}</Link>
      -
      <Link href={links.privacy} rel="noopener noreferrer" target="_blank">{t('footer.privacy')}</Link>
    </LinksWrapper>
  );
};

const Footer = () => {
  const t = useTranslation();
  return (
    <>
      <ContactWrapper>
        <FooterLink href={links.account} rel="noopener noreferrer" target="_blank">{t('footer.account')}</FooterLink>
        <FooterLink href={links.contact} rel="noopener noreferrer" target="_blank">{t('footer.contact')}</FooterLink>
      </ContactWrapper>
      <LinksContainer />
      <AddressText>{t('footer.legal')}</AddressText>
    </>
  );
};

export default Footer;
