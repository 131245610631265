import styled from 'styled-components/macro';
import screenSizes from '@customTypes/constants/screen-size.constant';

export const FooterLink = styled.a`
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  text-decoration: none;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    font-size: 12px;
    margin-top: 30px;
  }
`;

export const AddressText = styled.div`
  margin: 50px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #747474;
  width: 500px;
  text-align: center;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    margin: 8px 0 60px 0;
    font-size: 9px;
    width: 310px;
  }
`;

export const IconImageWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 460px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    margin-top: 20px;
    width: 200px;
  }
`;

export const IconImage = styled.img`
  width: 46px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    width: 19px;
  }
`;

export const Link = styled.a`
  color: #747474;
  align-self: center;
  font-size: 14px;
  font-weight: 400;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    font-size: 9px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  color: #747474;
  flex-direction: row;
  margin-top: 48px;
  justify-content: space-between;
  width: 300px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    width: 200px;
    font-size: 9px;
    margin-top: 25px;
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  justify-content: space-around;
  width: 400px;
  font-size: 16px;
  @media screen and (max-width: ${screenSizes.desktop.width}) {
    text-align: center;
    font-size: 9px;
    margin-top: 25px;
    flex-direction: column;
  }
`;
